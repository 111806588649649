import React from "react";
import styled from "styled-components";
import { Wrapper, Container } from "../../global";

import { FC } from "react";
import { Typography as _Typography, Grid } from "@material-ui/core";

const LegalDocuments: FC = () => {
  return (
    <Wrapper id="legal">
      <Container>
        <Grid container direction="column">
          <GridItem container item justify="center" alignItems="center">
            <Typography variant="h3" style={{ textAlign: "center" }}>
              PRIVACY POLICY
            </Typography>
          </GridItem>
          <GridItem container item>
            <Typography variant="subtitle2">Last updated 19 October 2021</Typography>
            <Typography variant="body1">
              Cognitive Performance Labs Limited (“CPL”, “we” or “us”) takes your privacy seriously and handles your
              personal information in accordance with all applicable privacy laws. This Privacy Policy sets out what
              information we gather, how we use it and how you can correct or change it.
            </Typography>
            <Typography variant="body1">
              We may change this Privacy Policy at any time and if we do, we will post the updated policy on the
              website. By continuing to use the website, you agree to the updated Privacy Policy.
            </Typography>
            <TypographyHeader variant="h4">Information CPL collects</TypographyHeader>
            <Typography variant="body1">
              We collect personal information that you provide to us directly, including when you register to use the
              Ako Maps Platform, interact and use with the Ako Maps Platform or parts or resources from the Ako Maps
              Platform, or contact us (by email or otherwise).
            </Typography>
            <Typography variant="body1">
              We also collect information automatically using cookies, or other web measurements tools, as you use the
              Ako Maps platform and our other websites.
            </Typography>
            <Typography variant="body1">The personal information we collect from you may include:</Typography>
            <List>
              <li>
                <_Typography variant="body1">your name and email address;</_Typography>
              </li>
              <li>
                <_Typography variant="body1">
                  what organisation you are associated with, and if you are a teacher or student, the name of the school
                  you are associated with;
                </_Typography>
              </li>
              <li>
                <_Typography variant="body1">
                  a record of how you use the Ako Maps Platform, including the amount of time you spend on the platform,
                  your activities and the content you create on the platform, and whether or how you share content on
                  the platform; and
                </_Typography>
              </li>
              <li>
                <_Typography variant="body1">a record of your communications with CPL.</_Typography>
              </li>
            </List>
            <TypographyHeader variant="h4">What we use your information for</TypographyHeader>
            <Typography variant="body1">
              We will only hold and use your personal information for the purposes set out in this Privacy Policy, and
              for any purpose disclosed at the time of collection or for any related purposes. Generally we will only
              use your personal information for the following purposes:
            </Typography>
            <List>
              <li>
                <_Typography variant="body1">to register you as a user of the Ako Maps Platform;</_Typography>
              </li>
              <li>
                <_Typography variant="body1">
                  to facilitate your access to, and use of, the Ako Maps Platform and for personalising your experience;
                </_Typography>
              </li>
              <li>
                <_Typography variant="body1">
                  to operate the Ako Maps Platform and analyse your use of the Ako Maps Platform;
                </_Typography>
              </li>
              <li>
                <_Typography variant="body1">
                  if you are given access to, or use, content created by other users of the Ako Maps Platform, to
                  analyse how you use that user’s content;
                </_Typography>
              </li>
              <li>
                <_Typography variant="body1">
                  to send you map share notifications, when we have been instructed by another user to do so. When we
                  send you map share notifications, we assume that you have consented to receive that map share
                  notification;
                </_Typography>
              </li>
              <li>
                <_Typography variant="body1">
                  to respond to any queries you make, including assisting with the resolution of technical support
                  issues;
                </_Typography>
              </li>
              <li>
                <_Typography variant="body1">
                  where you have provided your consent, to send you marketing messages (including by email) about the
                  Ako Maps Platform, and other CPL products we think you might be interested in;
                </_Typography>
              </li>
              <li>
                <_Typography variant="body1">
                  for our research and development purposes, including contacting you to ask you to evaluate and give us
                  feedback on the Ako Maps Platform;
                </_Typography>
              </li>
              <li>
                <_Typography variant="body1">
                  managing our relationship with you, and exercising our rights under our contracts with you;
                </_Typography>
              </li>
              <li>
                <_Typography variant="body1">
                  to prevent illegal activity that threatens the Ako Maps Platform, our computer systems or networks;
                  and
                </_Typography>
              </li>
              <li>
                <_Typography variant="body1">to comply with any applicable laws and/or regulations.</_Typography>
              </li>
            </List>
            <TypographyHeader variant="h4">Sharing your personal information</TypographyHeader>
            <Typography variant="body1">CPL may disclose your personal information to:</Typography>
            <List>
              <li>
                <_Typography variant="body1">
                  our service providers, including people or organisations that provide Ako Maps courses;
                </_Typography>
              </li>
              <li>
                <_Typography variant="body1">our partners and related companies;</_Typography>
              </li>
              <li>
                <_Typography variant="body1">
                  if a third party organisation invited you to use the Ako Maps platform, that third party organisation;
                </_Typography>
              </li>
              <li>
                <_Typography variant="body1">
                  if you are given access to, or use, content created by another user of the Ako Maps Platform, that
                  user to allow then to analyse how you have used that content;
                </_Typography>
              </li>
              <li>
                <_Typography variant="body1">
                  any other third parties where you have consented to such disclosure or if we are required to disclose
                  your personal information by law or court order.
                </_Typography>
              </li>
            </List>
            <TypographyHeader variant="h4">Web measurement tools and cookies</TypographyHeader>
            <Typography variant="body1">
              We use web measurement tools, including cookies, to give us insights as to how you are using the Ako Maps
              platform and our other websites. This information is anonymised. We use that information to improve and to
              give you a personalised user experience when you are using or browsing the Ako Maps platform and our other
              websites.
            </Typography>
            <Typography variant="body1">
              Ako Maps Platform is protected by reCAPTCHA and the Google{" "}
              <a href="https://policies.google.com/privacy">Privacy Policy</a> applies.
            </Typography>
            <Typography variant="body1">
              You can change the settings on your browser to disable cookies while you are using the Website.
            </Typography>
            <TypographyHeader variant="h4">Your rights</TypographyHeader>
            <Typography variant="body1">
              You are entitled to access the personal information we hold about you, request that we amend it if it is
              incorrect or request that we delete your data. If you would like to do any of these things or for further
              information about our privacy policy, please contact us at{" "}
              <a href="mailto:office@cognitiveperformancelabs.com">office@cognitiveperformancelabs.com</a>.
            </Typography>
          </GridItem>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default LegalDocuments;

const GridItem = styled(Grid)`
  width: 75%;
  margin: auto;
`;
const Typography = styled(_Typography)`
  padding-bottom: 24px;
  width: 100%;
  > a {
    color: ${props => props.theme.color.secondary};
  }
`;
const TypographyHeader = styled(Typography)`
  color: ${props => props.theme.color.secondary};
`;
const List = styled.ul`
  color: ${props => props.theme.color.secondary};
  > li {
    display: list-item;
    > p {
      color: black;
    }
  }
`;
