import React, { FC } from "react";

import Layout from "../../components/common/layout/layout";
import SEO from "../../components/common/layout/seo";
import Navigation from "../../components/common/navigation/navigation";
import PrivacyContent from "../../components/common/legal/PrivacyContent";

import Footer from "../../components/sections/footer";

import theme from "@studytools/core/ui/theme/muiTheme";
import { ThemeProvider } from "@material-ui/core";

const PrivacyPage: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Ako Maps" />
        <Navigation />
        <PrivacyContent />
        <Footer />
      </Layout>
    </ThemeProvider>
  );
};

export default PrivacyPage;
